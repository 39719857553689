import React from 'react'
import { ModelList } from '@layouts'
import { Layout } from '@globals'

const IndexPage = () => {
  return (
    <Layout>
      <ModelList headingLineOne="All" headingLineTwo="Models" />
    </Layout>
  )
}
export default IndexPage
